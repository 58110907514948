import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventService } from '../../service/event.service';
import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { EventDTO } from '../../../../models/EventDTO';
import e from 'express';


registerLocaleData(localeFr, 'fr-FR');


@Component({
  selector: 'app-gestion-evenement',
  templateUrl: './gestion-evenement.component.html',
  styleUrl: './gestion-evenement.component.css',
})
export class GestionEvenementComponent implements OnInit {
  currentDate = new Date();
  currentMonth = '';
  currentYear = 0;
  selectedDate = new Date();
  daysInMonth: any[] = [];
  weekDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  selectedDayEvents: any[] = [];
  isAddEventVisible = false;
  inputDate = '';
  selectedDayName: string = ''; // Nom du jour sélectionné
  selectedFullDate: string = ''; // Date complète formatée en français
  private events: { [key: string]: any[] } = {}; // Événements par date
  imagePreviewUrl: string | null = null;
  selectedEvent: EventDTO | null = null; // Holds the selected event details
  isPopupVisible = false; // Variable pour le contrôle de la popup
  isEditMode = false; // Indique si le formulaire est en mode modification
  
  newEvent = {
    name: '',
    description: '',
    dateEvent: '',
  };
  constructor(
    private notification: NzNotificationService,
    private eventService : EventService, 
  ) { }
  ngOnInit() {
    this.updateCalendar();
    this.updateSelectedDateInfo();
    this.loadEvents();
  }

  isDirecteur(): boolean {
    const userRole = localStorage.getItem('_sid');
    return userRole === 'Directeur';
  }
  isParent(): boolean {
    const userRole = localStorage.getItem('_sid');
    return userRole === 'Parent';
  }
  isFormateur(): boolean {
    const userRole = localStorage.getItem('_sid');
    return userRole === 'Educateur';
  }
  loadEvents() {
    const today = new Date(); // Get today's date
    this.eventService.getEvenementsAfterToday().subscribe({
      next: (events) => {
        this.events = events
          .filter((event: any) => new Date(event.dateEvent) >= today) // Filter out events before today
          .reduce((acc: any, event: any) => {
            const dateKey = formatDate(event.dateEvent, 'yyyy-MM-dd', 'fr-FR');
            if (!acc[dateKey]) acc[dateKey] = [];
            acc[dateKey].push(event);
            return acc;
          }, {});
        this.updateCalendar(); // Refresh the calendar with the new data
      },
      error: (err) => {
        console.error('Erreur lors du chargement des événements:', err);
      },
    });
  }
  
  
  updateCalendar() {
    const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  
    this.currentMonth = firstDay.toLocaleString('fr-FR', { month: 'long' });
    this.currentYear = firstDay.getFullYear();
  
    const prevMonthDays = new Date(firstDay.getFullYear(), firstDay.getMonth(), 0).getDate();
    const daysInCurrentMonth = lastDay.getDate();
    const firstDayIndex = firstDay.getDay();
  
    const days = [];
  
    // Jours du mois précédent
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      days.push({
        date: prevMonthDays - i,
        isPrevMonth: true,
        isNextMonth: false,
        isToday: false,
        hasEvent: false,
      });
    }
  
    // Jours du mois courant
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const isToday = this.isSameDay(new Date(), new Date(this.currentYear, this.currentDate.getMonth(), i));
      const formattedDate = new Date(this.currentYear, this.currentDate.getMonth(), i).toISOString().split('T')[0];
  
      days.push({
        date: i,
        isPrevMonth: false,
        isNextMonth: false,
        isToday,
        hasEvent: !!this.events[formattedDate],
      });
    }
  
    // Jours du mois suivant
    const remainingDays = 7 - (days.length % 7);
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        date: i,
        isPrevMonth: false,
        isNextMonth: true,
        isToday: false,
        hasEvent: false,
      });
    }
  
    this.daysInMonth = days;
  }
  updateSelectedDateInfo(): void {
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    this.selectedDayName = this.selectedDate.toLocaleDateString('fr-FR', { weekday: 'long' });
    this.selectedFullDate = this.selectedDate.toLocaleDateString('fr-FR', options);
  }
  isSameDay(d1: Date, d2: Date) {
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }
  goToPreviousMonth() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
    this.updateCalendar();
  }
  goToNextMonth() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
    this.updateCalendar();
  }
  goToToday() {
    this.currentDate = new Date();
    this.updateCalendar();
  }
  goToDate() {
    const [month, year] = this.inputDate.split('/').map((v) => +v);
    if (!isNaN(month) && !isNaN(year)) {
      this.currentDate = new Date(year, month - 1, 1);
      this.updateCalendar();
    }
  }
  selectDay(day: any) {
    // Ne pas autoriser la sélection de dates antérieures à aujourd'hui
    const today = new Date();
    const selectedDate = new Date(this.currentYear, this.currentDate.getMonth(), day.date);
  
    if (selectedDate < today) {
      this.notification.create('warning', 'Date non valide', 'Vous ne pouvez pas sélectionner une date avant aujourd’hui.');
      return; // Stop further execution if the date is in the past
    }
  
    this.selectedDate = selectedDate;
    this.updateSelectedDateInfo(); // Mettre à jour les informations de la date sélectionnée
    this.isAddEventVisible = true; // Show the add event popup
  
    // Charger les événements associés à ce jour
    const selectedDateKey = this.selectedDate.toISOString().split('T')[0]; // Clé sous format yyyy-MM-dd
    this.selectedDayEvents = this.events[selectedDateKey] || [];
  }
  

  toggleAddEvent() {
    this.isAddEventVisible = !this.isAddEventVisible;
    this.isEditMode = false; // Mode par défaut : ajout
  
  }

  openEditEvent(event: any) {
    this.isAddEventVisible = true;
    this.isEditMode = true; // Activer le mode modification
    this.newEvent = { 
      name: event.titre, 
      description: event.description, 
      dateEvent: event.dateEvent, 

    };
  }
  resetSelectedEvent() {
    this.selectedEvent = null;
}
addEvent() {
  if (this.newEvent.name ) {
    const selectedDate = new Date(this.selectedDate);
    const today = new Date();

    // Vérifier si la date de l'événement est à partir d'aujourd'hui
    if (selectedDate >= today) {
      const { name, description} = this.newEvent;
      const formattedDate = selectedDate.toISOString().split('T')[0];

      this.eventService.createEvenement(
        name,
        description,
        1, // Exemple d'ID utilisateur
        formattedDate,
        this.selectedImage ?? undefined
      ).subscribe({
        next: (event) => {
          console.log('Événement créé:', event);

          // Ajouter l'événement à la liste locale
          if (!this.events[formattedDate]) {
            this.events[formattedDate] = [];
          }
          this.events[formattedDate].push(event);

          // Ajouter un indicateur visuel au jour correspondant
          const dayIndex = this.daysInMonth.findIndex(day =>
            !day.isPrevMonth &&
            !day.isNextMonth &&
            this.isSameDay(
              this.selectedDate,
              new Date(this.currentYear, this.currentDate.getMonth(), day.date)
            )
          );
          if (dayIndex !== -1) {
            this.daysInMonth[dayIndex].hasEvent = true;
          }

          // Réinitialiser le formulaire
          this.resetForm();

          // Afficher une notification de succès
          this.notification.create(
            'success',
            'Événement créé avec succès !',
            `L'événement "${event.titre}" a été ajouté avec succès.`,
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 3000,
            }
          );
        },
        error: (err) => {
          console.error('Erreur lors de la création de l’événement:', err);
        },
      });
    } else {
      console.warn('Vous ne pouvez pas ajouter d’événement avant la date d’aujourd’hui.');
    }
  } else {
    console.warn('Le nom de l’événement ou l’heure de début est manquant.');
  }
  this.toggleAddEvent();
}


saveEditedEvent() {
  // Logique pour sauvegarder les modifications de l'événement
  console.log('Modification de l\'événement', this.newEvent);
  this.isAddEventVisible = false;
}
onDeleteEvent(): void {
  if (this.selectedEvent?.id) {
    this.eventService.deleteEvenement(this.selectedEvent.id).subscribe({
      next: (response) => {
        console.log('Event deleted successfully:', response);

        // Supprimer l'événement supprimé de la liste des événements du jour sélectionné
        this.selectedDayEvents = this.selectedDayEvents.filter(event => event.id !== this.selectedEvent?.id);

        // Afficher une notification de succès
        this.notification.create(
          'success',
          'Événement supprimé avec succès !',
          `L'événement "${this.selectedEvent?.titre}" a été supprimé.`,
          {
            nzPlacement: 'bottomLeft',
            nzDuration: 3000,
          }
        );

        this.closePopup(); // Fermer la fenêtre contextuelle
      },
      error: (error) => {
        console.error('Error deleting event:', error);

        // Afficher une notification d'erreur
        this.notification.create(
          'error',
          'Erreur lors de la suppression',
          'Une erreur est survenue lors de la tentative de suppression de l’événement. Veuillez réessayer.',
          {
            nzPlacement: 'bottomLeft',
            nzDuration: 3000,
          }
        );
      },
    });
  } else {
    console.error('No event ID found for deletion.');

    // Afficher une notification d'erreur si aucun ID n'est trouvé
    this.notification.create(
      'error',
      'Erreur',
      "Aucun événement sélectionné pour suppression.",
      {
        nzPlacement: 'bottomLeft',
        nzDuration: 3000,
      }
    );
  }
}


openEventPopup(event: any): void {
  console.log('ID de l’événement:', event.id); // Vérifiez l’ID ici
  if (event.id) {
    this.fetchEventDetails(event.id); // Appeler la méthode pour obtenir les détails de l'événement
    this.isPopupVisible = true; // Afficher la popup
  }
}

closePopup(): void {
  this.isPopupVisible = false; // Désactive la modal
  this.selectedEvent = null; // Réinitialise l'événement sélectionné

}

  resetForm() {
    this.newEvent = { name: '', description: '', dateEvent: '' };
    this.isAddEventVisible = false;
    this.selectedImage = null;
    this.imagePreviewUrl = null;
  }
  fetchEventDetails(eventId: number): void {
    this.eventService.getEvenementById(eventId).subscribe({
      next: (event) => {
        console.log('Détails de l’événement récupéré:', event); // Ajoutez ce log pour debug
        this.selectedEvent = event; // Stocker les détails de l'événement récupéré
      },
      error: (err) => {
        console.error('Erreur lors de la récupération des détails de l’événement:', err);
      },
    });
  }
  
  selectedImage: File | null = null;
  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedImage = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrl = reader.result as string;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  } 

  updateEvenement() {
    if (this.selectedEvent && this.newEvent.name && this.newEvent.dateEvent) { 
      const { id, description, dateEvent } = this.selectedEvent; // Removed timeFrom
  
      // Formater la date au format yyyy-MM-dd
      const formattedDate = new Date(this.newEvent.dateEvent).toISOString().split('T')[0];
  
      this.eventService.updateEvenement(
        id!,
        this.newEvent.name, // Use 'name' instead of 'titre'
        description,
        formattedDate,
        this.selectedImage ?? undefined
      ).subscribe({
        next: (updatedEvent) => {
          console.log('Événement mis à jour avec succès', updatedEvent);
    
          // Mettre à jour les événements dans la liste locale
          const selectedDateKey = this.selectedDate.toISOString().split('T')[0];
          this.events[selectedDateKey] = this.events[selectedDateKey].map(event =>
            event.id === updatedEvent.id ? updatedEvent : event
          );
    
          // Mettre à jour les jours du calendrier
          const dayIndex = this.daysInMonth.findIndex(day =>
            !day.isPrevMonth &&
            !day.isNextMonth &&
            this.isSameDay(
              this.selectedDate,
              new Date(this.currentYear, this.currentDate.getMonth(), day.date)
            )
          );
          if (dayIndex !== -1) {
            this.daysInMonth[dayIndex].hasEvent = true;
          }
    
          // Afficher une notification de succès
          this.notification.create(
            'success',
            'Événement mis à jour avec succès !',
            `L'événement "${updatedEvent.titre}" a été mis à jour.`, // Use 'name' here
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 3000,
            }
          );
    
          this.closePopup(); // Fermer la popup
        },
        error: (err) => {
          console.error('Erreur lors de la mise à jour de l’événement:', err);
    
          // Afficher une notification d'erreur
          this.notification.create(
            'error',
            'Erreur lors de la mise à jour',
            'Une erreur est survenue lors de la tentative de mise à jour de l’événement. Veuillez réessayer.',
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 3000,
            }
          );
        },
      });
    } else {
      console.warn('Données manquantes pour la mise à jour de l’événement.');
    }
  }
  
  confirmDeleteEvent(): void {
    if (this.selectedEvent?.id) {
      this.eventService.deleteEvenement(this.selectedEvent.id).subscribe({
        next: (response) => {
          console.log('Event deleted successfully:', response);
  
          // Remove the deleted event from the list of selected day events
          this.selectedDayEvents = this.selectedDayEvents.filter(event => event.id !== this.selectedEvent?.id);
  
          // Show success notification
          this.notification.create(
            'success',
            'Événement supprimé avec succès !',
            `L'événement "${this.selectedEvent?.titre}" a été supprimé.`,
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 3000,
            }
          );
  
          // Refresh the page after successful deletion
          window.location.reload();
        },
        error: (error) => {
          console.error('Error deleting event:', error);
  
          // Show error notification
          this.notification.create(
            'error',
            'Erreur lors de la suppression',
            'Une erreur est survenue lors de la tentative de suppression de l’événement. Veuillez réessayer.',
            {
              nzPlacement: 'bottomLeft',
              nzDuration: 3000,
            }
          );
        },
      });
    } else {
      console.error('No event ID found for deletion.');
  
      // Show error notification
      this.notification.create(
        'error',
        'Erreur',
        "Aucun événement sélectionné pour suppression.",
        {
          nzPlacement: 'bottomLeft',
          nzDuration: 3000,
        }
      );
    }
  }
  
  
}