<!DOCTYPE html>
<html lang="fr">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="keywords" content="calendrier, événements, rappels, javascript, html, css, codage open source">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
    integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />
  <title>Calendrier d'événements</title>
</head>
<body>

<div class="container">
  <div class="left">
    <div class="calendar">
      <div class="month">
        <i class="fas fa-angle-left prev" (click)="goToPreviousMonth()"></i>
        <div class="date">{{ currentMonth }} {{ currentYear }}</div>
        <i class="fas fa-angle-right next" (click)="goToNextMonth()"></i>
      </div>
      <div class="weekdays">
        <div *ngFor="let day of weekDays">{{ day }}</div>
      </div>
      <div class="days">
        <div *ngFor="let day of daysInMonth; let i = index" class="day" [class.prev-date]="day.isPrevMonth"
          [class.next-date]="day.isNextMonth" [class.active]="day.isToday" [class.event]="day.hasEvent"
          (click)="selectDay(day)">
          {{ day.date }}
        </div>
      </div>
      <div class="goto-today">
        <div class="goto">
          <input type="text" placeholder="mm/aaaa" class="date-input" [(ngModel)]="inputDate" />
          <button class="goto-btn" (click)="goToDate()">Aller</button>
        </div>
        <button class="today-btn" (click)="goToToday()">Aujourd'hui</button>
      </div>
    </div>
  </div>
  <br>
  <div class="right">
    <div class="today-date">
      <div class="event-day">{{ selectedDayName }}</div>
      <div class="event-date">{{ selectedFullDate }}</div>
    </div>
    <br>
    <br>
    <div class="events">
      <!-- Liste des événements si aucun événement n'est sélectionné -->
      <div *ngIf="!selectedEvent">
        <div *ngFor="let event of selectedDayEvents" class="event" (click)="openEventPopup(event)">
          <div class="title">
            <i class="fas fa-circle"></i>
            <div class="event-title">{{ event.titre }}</div>
          </div>
          
        </div>
        <div *ngIf="selectedDayEvents.length === 0" class="no-event">
          Aucun événement pour ce jour.
        </div>
      </div>
    </div>

    <div class="custom-modal" *ngIf="isPopupVisible">
      <div class="custom-modal-content">
        <span class="close-button" (click)="closePopup()">&times;</span>
        <h2 class="modal-title">Détails de l'événement</h2>
        <div class="modal-body">
          <!-- Display the event photo if it exists -->
          <div class="event-photo" *ngIf="selectedEvent?.photoUrl">
            <img [src]="selectedEvent?.photoUrl" [alt]="selectedEvent?.titre" class="event-image" />
          </div>
          <p class="event-details"><strong>Titre :</strong> {{ selectedEvent?.titre }}</p>
          <p class="event-details"><strong>Description :</strong> {{ selectedEvent?.description }}</p>
          <p class="event-details"><strong>Date de création :</strong> {{ selectedFullDate }}</p>

          
        </div>
        <!-- Action buttons -->
        <div class="modal-actions" *ngIf="isDirecteur() || isFormateur()">
          <button class="btn btn-delete" data-bs-toggle="modal" data-bs-target="#confirmDeleteEventModal">
            Supprimer
          </button> <!--<button class="btn btn-edit" (click)="openEditEvent(selectedEvent)">Modifier</button>-->
        </div>
      </div>
    </div>
    

    <div class="add-event-wrapper" [class.active]="isAddEventVisible" *ngIf="isDirecteur() || isFormateur()">
      <div class="add-event-header">
        <div class="title">{{ isEditMode ? 'Modifier un événement' : 'Ajouter un événement' }}</div>
        <i class="fas fa-times close" (click)="toggleAddEvent()"></i>
      </div>
      <div class="add-event-body">
        <div class="add-event-input">
          <input type="text" placeholder="Nom de l'événement" class="event-name" [(ngModel)]="newEvent.name" />
        </div>
        <div class="add-event-input">
          <input type="text" placeholder="Description" class="event-name" [(ngModel)]="newEvent.description" />
        </div>
       <!-- <div class="add-event-input">
          <input type="text" placeholder="Heure de début" class="event-time-from" [(ngModel)]="newEvent.timeFrom" />
        </div>-->
        <div class="add-event-input">
          <label for="event-image" class="event-image-label">
            <i class="fas fa-image"></i> Ajouter une image
          </label>
          <input type="file" id="event-image" class="event-image-input" (change)="onImageSelected($event)" />
        </div>
        <div class="image-preview" *ngIf="selectedImage">
          <img [src]="imagePreviewUrl" alt="Aperçu de l'image" />
        </div>
      </div>
      <div class="add-event-footer">
        <button class="add-event-btn" (click)="isEditMode ? saveEditedEvent() : addEvent()">
          {{ isEditMode ? 'Modifier' : 'Ajouter' }}
        </button>
      </div>
    </div>
  </div>
  <button class="add-event" (click)="toggleAddEvent()">
    <i class="fas fa-plus"></i>
  </button>
</div>

<!-- Modal de confirmation pour la suppression -->
<div class="modal fade" id="confirmDeleteEventModal" tabindex="-1" aria-labelledby="confirmDeleteEventModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="confirmDeleteEventModalLabel">
          Confirmer la suppression
        </h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Êtes-vous sûr de vouloir supprimer l'événement <strong>{{ selectedEvent?.titre }}</strong> ?
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Non, annuler
        </button>
        <button type="button" class="btn btn-primary" (click)="confirmDeleteEvent()" data-bs-dismiss="modal">
          Oui, supprimer
        </button>
      </div>
    </div>
  </div>
</div>

</body>
</html>
