import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoginServerService } from '../../../features/authentication/services/login-server.service';
import { ProfilService } from '../service/profil.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/user.service';
import { PhotoDTO } from '../../../models/PhotoDTO';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ChangePasswordRequest } from '../../../models/ChangePasswordRequest';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css'], // Fix typo here: styleUrl -> styleUrls
})
export class ProfilComponent {
  role: string | null = null; // Variable to store the role
  userEmail: string | null = null; // Variable to store the email
  profileImageUrl: string = '../../../../assets/img/Admin.png'; // Default image path
  userProfile: any;
  editProfileForm!: FormGroup;
  private userIdKey = parseInt(localStorage.getItem('userId') as string, 10);
  changePasswordForm!: FormGroup;
  showOldPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  dropdownVisible = false;

  constructor(
    private authProvider: LoginServerService,
    private profilService: ProfilService,
    private userService: UserService,
    private notification: NzNotificationService,
    private fb: FormBuilder,

    @Inject(PLATFORM_ID) private platformId: Object // Inject PLATFORM_ID
  ) {
    this.editProfileForm = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      userEmail: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|fr)$/)]],
      userPhoneNumber: ['', [Validators.required, Validators.pattern(/^\+216\d{8}$/)]],
      cinParent: ['', [Validators.required, Validators.pattern(/^\d{8}$/)]],
    });
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadRole();
      this.loadUserEmail();
      this.loadProfilePhoto(); // Always load the profile photo

      // Optionally load user profile (if needed)
      this.loadProfile();
    }
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  openEditModal(): void {
    this.loadProfile();
  }

  loadProfile() {
    this.profilService.getProfilById(this.userIdKey).subscribe({
      next: (data) => {
        this.userProfile = data;
        this.editProfileForm.patchValue(data); // Populate form
      },
      error: (err) => console.error('Error loading profile:', err),
    });
  }

  saveProfile() {
    if (this.editProfileForm.valid) {
      this.profilService.updateProfil(this.userIdKey, this.editProfileForm.value).subscribe({
        next: (response) => {
          console.log('Profile updated successfully:', response);
          this.userProfile = response; // Update displayed data
  
          // Notification de succès
          this.notification.create(
            'success',
            'Succès',
            'Compte modifié avec succès.',
            { nzPlacement: 'bottomLeft', nzDuration: 3000 }
          );
        },
        error: (err) => {
          console.error('Error updating profile:', err);
  
          // Notification d'erreur
          this.notification.create(
            'error',
            'Échec',
            'Une erreur est survenue lors de la modification du compte.',
            { nzPlacement: 'bottomLeft', nzDuration: 3000 }
          );
        },
      });
    } else {
      console.error('Form is invalid');
  
      // Optional: Show a notification for invalid form
      this.notification.create(
        'error',
        'Formulaire invalide',
        'Veuillez vérifier les champs obligatoires.',
        { nzPlacement: 'bottomLeft', nzDuration: 3000 }
      );
    }
  }
  

  loadProfilePhoto(): void {
    // Check localStorage first to see if the profile photo is already saved
    const savedImage = localStorage.getItem('profileImage');
    if (savedImage) {
      this.profileImageUrl = savedImage; // Use saved image if available
    } else {
      // If no saved image, try fetching from backend
      this.userService.getPhotoByUserId(this.userIdKey).subscribe({
        next: (photoUrl: string) => {
          this.profileImageUrl = photoUrl || '../../../../assets/img/Admin.png'; // Fallback if empty or invalid
          localStorage.setItem('profileImage', this.profileImageUrl); // Cache the photo URL
        },
        error: (err) => {
          console.error('Error fetching photo:', err);
          this.profileImageUrl = '../../../../assets/img/Admin.png'; // Fallback to default if error occurs
        },
      });
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const dto: PhotoDTO = {
        userId: this.userIdKey,
        photo: file, // Assuming backend can handle multipart/form-data
      };

      this.userService.uploadPhoto(dto).subscribe({
        next: (response: string) => {
          const uploadedPhotoUrl = response.split('Photo uploaded successfully: ')[1];
          this.profileImageUrl = uploadedPhotoUrl || '../../../../assets/img/Admin.png'; // Fallback if empty or invalid
          localStorage.setItem('profileImage', uploadedPhotoUrl); // Cache uploaded photo
        },
        error: (err) => {
          console.error('Error uploading photo:', err);
          this.profileImageUrl = '../../../../assets/img/Admin.png'; // Fallback to default if error occurs
        },
      });
    }
  }

  private loadRole(): void {
    const role = localStorage.getItem('_sid');
    this.role = role;
  }

  private loadUserEmail(): void {
    const userEmail = localStorage.getItem('name');
    this.userEmail = userEmail;
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

  handleLogout(): void {
    this.authProvider.logout();
  }
  
  changePassword(): void {
    if (this.changePasswordForm.valid) {
      const { oldPassword, newPassword, confirmPassword } = this.changePasswordForm.value;
  
      if (newPassword !== confirmPassword) {
        this.notification.create(
          'error',
          'Erreur',
          'Les mots de passe ne correspondent pas.',
          { nzPlacement: 'bottomLeft', nzDuration: 3000 }
        );
        return;
      }
  
      // Le modal ne fait que confirmer, on lance ici la modal
      // On ne modifie pas le mot de passe directement
    } else {
      this.notification.create(
        'error',
        'Erreur',
        'Veuillez remplir tous les champs correctement.',
        { nzPlacement: 'bottomLeft', nzDuration: 3000 }
      );
    }
  }
  
  
  

  get passwordMismatch(): boolean {
    const newPassword = this.changePasswordForm.get('newPassword')?.value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword')?.value;
    return newPassword && confirmPassword && newPassword !== confirmPassword;
  }

  togglePasswordVisibility(field: 'old' | 'new' | 'confirm'): void {
    if (field === 'old') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
  confirmChangePassword(): void {
    if (this.changePasswordForm.valid) {
      const userId = parseInt(localStorage.getItem('userId') as string, 10); // Récupération automatique du userId depuis localStorage
  
      if (!userId) {
        this.notification.create(
          'error',
          'Erreur',
          'UserId introuvable. Veuillez vous reconnecter.',
          { nzPlacement: 'bottomLeft', nzDuration: 3000 }
        );
        return;
      }
  
      const { oldPassword, newPassword, confirmPassword } = this.changePasswordForm.value;
  
      if (newPassword !== confirmPassword) {
        this.notification.create(
          'error',
          'Erreur',
          'Les mots de passe ne correspondent pas.',
          { nzPlacement: 'bottomLeft', nzDuration: 3000 }
        );
        return;
      }
  
      this.userService.changePassword(userId, { oldPassword, newPassword, confirmPassword }).subscribe({
        next: () => {
          this.notification.create(
            'success',
            'Succès',
            'Mot de passe changé avec succès.',
            { nzPlacement: 'bottomLeft', nzDuration: 3000 }
          );
          this.changePasswordForm.reset();
        },
        error: () => {
          this.notification.create(
            'error',
            'Erreur',
            'Une erreur est survenue lors du changement de mot de passe.',
            { nzPlacement: 'bottomLeft', nzDuration: 3000 }
          );
        },
      });
    } else {
      this.notification.create(
        'error',
        'Erreur',
        'Veuillez remplir tous les champs correctement.',
        { nzPlacement: 'bottomLeft', nzDuration: 3000 }
      );
    }
  }
  
  
  openConfirmChangePassword(): void {
    // Here you can add any logic you need before showing the modal.
    // You can also reset form fields if necessary.
    // Example: this.changePasswordForm.reset();
  }
  
}
