import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

export const myRxStompConfig: InjectableRxStompConfig = {
  brokerURL: 'wss://preprod.beekids.back.dpc.com.tn/ws', // L'URL de votre serveur WebSocket
  connectHeaders: {
    Authorization: `Bearer ${localStorage.getItem('presence')}`, // Ajout du token d'authentification si nécessaire
  },
  heartbeatIncoming: 0, // Pas de 'ping' entrant
  heartbeatOutgoing: 20000, // Intervalle de 'ping' sortant (20 secondes ici)
  reconnectDelay: 5000, // Délai de reconnexion en cas de déconnexion
  debug: (msg: string): void => {
    console.log(msg); // Afficher les messages de debug (facultatif)
  },
};
