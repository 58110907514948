import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environement';
import { UserDTO } from '../../../models/UserDTO';
import { SignupRequestDto } from '../../../models/SignupRequestDto';
import { MessageResponseDto } from '../../../features/authentication/models/message-response/MessageResponseDto';
import { isPlatformBrowser } from '@angular/common';
import { EleveDTO } from '../../../models/EleveDTO';
import { PhotoDTO } from '../../../models/PhotoDTO';
import { UserConversation } from '../gestion-jardin/gestion-messagarie/UserConversation.model';
import { ChangePasswordRequest } from '../../../models/ChangePasswordRequest';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  // Helper method to get headers with the token
  private getAuthHeaders(): { [header: string]: string } {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('presence');
      return token ? { Authorization: `Bearer ${token}` } : {};
    }
    return {};
  }

  // Method to get all non-archived users
  getAllNonArchivedUsers(): Observable<UserDTO[]> {
    return this.http.get<UserDTO[]>(`${this.baseUrl}/users/non-archived/parents`, {
      headers: this.getAuthHeaders(),
    });
  }


  // Method to get all non-archived formateurs
  getAllNonArchivedUsersFormateurs(): Observable<UserDTO[]> {
    return this.http.get<UserDTO[]>(`${this.baseUrl}/users/non-archived/formateurs`, {
      headers: this.getAuthHeaders(),
    });
  }

    // Method to get all non-archived formateurs
    getAllDirecteursFormateurs(userId:any): Observable<UserConversation[]> {
      return this.http.get<UserConversation[]>(`${this.baseUrl}/users/directeurs-formateurs?excludedUserId=${userId}`, {
        headers: this.getAuthHeaders(),
      });
    }

    // Method to get all user avec Discussion
    getAllUsersDiscussion (userId: any): Observable<UserDTO[]> {
      return this.http.get<UserDTO[]>(`${this.baseUrl}/messages/conversations/${userId}`, {
        headers: this.getAuthHeaders(),
      });
    }

  // Method to get a user by ID
  getUserById(userId: number): Observable<UserDTO> {
    return this.http.get<UserDTO>(`${this.baseUrl}/users/${userId}`, {
      headers: this.getAuthHeaders(),
    });
  }

  getChildrenByParent(parentId: number): Observable<EleveDTO[]> {
    return this.http.get<EleveDTO[]>(`${this.baseUrl}/users/${parentId}/children` , {
      headers: this.getAuthHeaders(),
    });
  }
  

  // Method to update a user
  updateUser(userId: number, userDTO: UserDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(`${this.baseUrl}/users/${userId}`, userDTO, {
      headers: this.getAuthHeaders(),
    });
  }

  // Method to archive a user
  archiveUser(userId: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/users/${userId}/archive`, null, {
      headers: this.getAuthHeaders(),
    }).pipe(catchError(this.handleError));
  }

  // Method to unarchive a user
  unarchiveUser(userId: number): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/users/${userId}/unarchive`, null, {
      headers: this.getAuthHeaders(),
    }).pipe(catchError(this.handleError));
  }


  deleteArchivedUser(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/users/delete/${userId}`, {
      headers: this.getAuthHeaders(),
    }).pipe(catchError(this.handleError));
  }

  // Method to register a new user
  registerUser(signUpRequest: SignupRequestDto): Observable<MessageResponseDto> {
  const url = `${this.baseUrl}/auth/signup`;
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.post<MessageResponseDto>(url, signUpRequest, { headers });
}

  // Method to get all archived users
  getAllArchivedUsers(): Observable<UserDTO[]> {
    return this.http.get<UserDTO[]>(`${this.baseUrl}/users/archived`, {
      headers: this.getAuthHeaders(),
    });
  }
  ajouterEleve(eleveDTO: EleveDTO): Observable<EleveDTO> {
    return this.http.post<EleveDTO>(`${this.baseUrl}/eleves/ajouter`, eleveDTO);
  }
  getAllChildren(): Observable<EleveDTO[]> {
    return this.http.get<EleveDTO[]>(`${this.baseUrl}/eleves/all`);
  }

  getChildById(id: number): Observable<EleveDTO> {
    return this.http.get<EleveDTO>(`${this.baseUrl}/eleves/${id}`);
  }

  updateChild(id: number, eleveDTO: EleveDTO): Observable<EleveDTO> {
    console.log('Sending update request for:', eleveDTO);  // Log for debugging
    return this.http.put<EleveDTO>(`${this.baseUrl}/eleves/update/${id}`, eleveDTO, {
      headers: this.getAuthHeaders(),
    });
  }
  

  deleteChild(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/eleves/delete/${id}`, {
      headers: this.getAuthHeaders(),
    }).pipe(
      catchError(this.handleError)
    );
  }

  uploadPhoto(dto: PhotoDTO): Observable<string> {
    const formData = new FormData();
    formData.append('userId', dto.userId.toString());
    formData.append('photo', dto.photo);
  
    return this.http.post(`${this.baseUrl}/users/add-photo`, formData, {
      headers: this.getAuthHeaders(),
      responseType: 'text',
    });
  }    


  
  getPhotoByUserId(userId: number): Observable<string> {
    return this.http.get(`${this.baseUrl}/users/get-photo/${userId}`, {
      headers: this.getAuthHeaders(),
      responseType: 'text', // Ensure correct response type
    });
  }
  
  
  
  // Error handling method
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  changePassword(userId: number, request: ChangePasswordRequest): Observable<{ message: string; status: string }> {
    return this.http.put<{ message: string; status: string }>(
      `${this.baseUrl}/users/${userId}/change-password`,
      request,
      {
        headers: this.getAuthHeaders(),
      }
    ).pipe(
      catchError(this.handleError) // Utilisation de la méthode d'erreur existante
    );
  }



}
