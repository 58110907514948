<header class="header">
  <div class="left">
    <img src="../../../../../assets/img/monkey.png" alt="Logo" class="logo">
    <h1>
      <span class="blue">BEE</span> <span class="orange">-KIDS</span>
    </h1>
    <div class="center d-flex align-items-center justify-content-center">
      <img src="../../../../../assets/img/logo.png" alt="Maternelle Kids Logo" class="maternelle-logo">
      <h1>
        <span class="blue" [routerLink]="['/admin/users/dashboard']">MATERNEL</span>
        <span class="orange" [routerLink]="['/admin/users/dashboard']">-KIDS</span>
      </h1>
    </div>
  </div>
  
  <div class="right">
    <i class="fas fa-bell icon"></i> <!-- Notification icon -->
    <i class="fas fa-envelope icon" [routerLink]="['/admin/users/messagaries']"></i> <!-- Messaging icon -->
    <!-- Profile container with dropdown -->
    <div class="profile-container">
      <img [src]="profileImageUrl" alt="Profile Image" class="rounded-circle" width="40" (click)="toggleDropdown()">
      <!-- Dropdown list with icons -->
      <div class="dropdown-content" [ngClass]="{'show': dropdownVisible}">
        <a href="#" class="dropdown-item">
          <i class="fas fa-user-circle"></i> Voir Profil
        </a>
        <a href="#" class="dropdown-item" (click)="handleLogout()">
          <i class="fas fa-sign-out-alt"></i> Déconnexion
        </a>
      </div>
    </div>
  </div>
</header>
<div class="container">
  <div class="main-body">
    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb" class="main-breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['../admin/user']">Accueil</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['../admin/user']">{{role}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">Voir Profil</li>
      </ol>
    </nav>
    <!-- /Breadcrumb -->
    <div class="row gutters-sm">
      <div class="col-md-4 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center position-relative">
              <!-- Profile Image Container -->
              <div class="profile-image-container">
                <img [src]="profileImageUrl" alt="Profile" class="rounded-circle" width="150">
                <a (click)="triggerFileInput()" class="upload-icon" title="Change Image">
                  <i class="fas fa-plus-circle"></i>
                </a>
                <input #fileInput type="file" accept="image/*" style="display: none" (change)="onFileSelected($event)">
              </div>


              <div class="mt-3">
                <h4>{{ userEmail }}</h4>
                <p class="text-secondary mb-1">{{ role }}</p>
                <p class="text-muted font-size-sm">Tunisie, Tunis</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3"></div>
      </div>
      <div class="col-md-8">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Nom</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ userProfile?.nom }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Prenom</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ userProfile?.prenom }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ userProfile?.userEmail }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Telephone</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ userProfile?.userPhoneNumber }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">CIN</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ userProfile?.cinParent }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-12">
                <button class="btn btn-info" (click)="openEditModal()" data-bs-toggle="modal"
                  data-bs-target="#editModal">
                  <i class="fas fa-edit"></i> Modifier
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <!-- Modal -->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editModalLabel">Modifier Profil</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="editProfileForm">
            <!-- Nom Field -->
            <div class="mb-3">
              <label for="nom" class="form-label">Nom</label>
              <input type="text" id="nom" class="form-control" formControlName="nom" />
              <div *ngIf="editProfileForm.get('nom')?.touched && editProfileForm.get('nom')?.invalid"
                class="text-danger">
                <small *ngIf="editProfileForm.get('nom')?.errors?.['required']">Nom est requis.</small>
                <small *ngIf="editProfileForm.get('nom')?.errors?.['minlength']">Nom doit avoir au moins 2
                  caractères.</small>
              </div>
            </div>

            <!-- Prenom Field -->
            <div class="mb-3">
              <label for="prenom" class="form-label">Prenom</label>
              <input type="text" id="prenom" class="form-control" formControlName="prenom" />
              <div *ngIf="editProfileForm.get('prenom')?.touched && editProfileForm.get('prenom')?.invalid"
                class="text-danger">
                <small *ngIf="editProfileForm.get('prenom')?.errors?.['required']">Prenom est requis.</small>
                <small *ngIf="editProfileForm.get('prenom')?.errors?.['minlength']">Prenom doit avoir au moins 2
                  caractères.</small>
              </div>
            </div>

            <!-- Email Field -->
            <div class="mb-3">
              <label for="userEmail" class="form-label">Email</label>
              <input type="email" id="userEmail" class="form-control" formControlName="userEmail" />
              <div *ngIf="editProfileForm.get('userEmail')?.touched && editProfileForm.get('userEmail')?.invalid"
                class="text-danger">
                <small *ngIf="editProfileForm.get('userEmail')?.errors?.['required']">Email est requis.</small>
                <small *ngIf="editProfileForm.get('userEmail')?.errors?.['email']">Email est invalide.</small>
              </div>
            </div>

            <!-- Telephone Field -->
            <div class="mb-3">
              <label for="userPhoneNumber" class="form-label">Téléphone</label>
              <input type="text" id="userPhoneNumber" class="form-control" formControlName="userPhoneNumber" />
              <div
                *ngIf="editProfileForm.get('userPhoneNumber')?.touched && editProfileForm.get('userPhoneNumber')?.invalid"
                class="text-danger">
                <small *ngIf="editProfileForm.get('userPhoneNumber')?.errors?.['required']">Téléphone est
                  requis.</small>
                <small *ngIf="editProfileForm.get('userPhoneNumber')?.errors?.['pattern']">Téléphone invalide. Format
                  attendu: +216XXXXXXXX</small>
              </div>
            </div>

            <!-- CIN Field -->
            <div class="mb-3">
              <label for="cinParent" class="form-label">CIN</label>
              <input type="number" id="cinParent" class="form-control" formControlName="cinParent" />
              <div *ngIf="editProfileForm.get('cinParent')?.touched && editProfileForm.get('cinParent')?.invalid"
                class="text-danger">
                <small *ngIf="editProfileForm.get('cinParent')?.errors?.['required']">CIN est requis.</small>
                <small *ngIf="editProfileForm.get('cinParent')?.errors?.['pattern']">CIN doit contenir exactement 8
                  chiffres.</small>
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
          <button type="button" class="btn btn-primary" (click)="saveProfile()"
            data-bs-dismiss="modal">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>


</div>