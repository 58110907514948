import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessage } from '../gestion-jardin/gestion-messagarie/chat-message.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environement';
import { isPlatformBrowser } from '@angular/common';
import { myRxStompConfig } from '../gestion-jardin/gestion-messagarie/myRxStompConfig';
import { UserConversation } from '../gestion-jardin/gestion-messagarie/UserConversation.model';
import { FullConversation } from '../gestion-jardin/gestion-messagarie/FullConversation .model';


@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private rxStompService: RxStompService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  connectToWebSocket(): void {
    this.rxStompService.configure(myRxStompConfig);
    this.rxStompService.activate();
    this.rxStompService.connected$.subscribe(() => {
      console.log('Connexion WebSocket établie');
    });
  }

  private getAuthHeaders(): { [header: string]: string } {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('presence');
      return token ? { Authorization: `Bearer ${token}` } : {};
    }
    return {};
  }

  // Envoyer un message
  sendMessage(chatMessage: ChatMessage): Observable<ChatMessage> {
    return this.http.post<ChatMessage>(
      `${this.baseUrl}/messages/send`,
      chatMessage,
      {
        headers: this.getAuthHeaders(),
      }
    );
  }

  // Récupérer les conversations de l'utilisateur
  getUserConversations(userId: number): Observable<UserConversation[]> {
    return this.http.get<UserConversation[]>(
      `${this.baseUrl}/messages/conversations`,
      {
        headers: this.getAuthHeaders(),
        params: { userId: userId.toString() },
      }
    );
  }

  // Récupérer les messages d'une conversation
  getMessages(userConnectedId: number, conversationId: number): Observable<FullConversation[]> {
    return this.http.get<FullConversation[]>(
      `${this.baseUrl}/messages/${conversationId}`,
      {
        headers: this.getAuthHeaders(),
        params: { userConnectedId: userConnectedId.toString() },
      }
    );
  }

  // Marquer un message comme lu
  markAsRead(messageId: number): Observable<ChatMessage> {
    return this.http.put<ChatMessage>(
      `${this.baseUrl}/messages/read/${messageId}`,
      null,
      { headers: this.getAuthHeaders() }
    );
  }

  // Recevoir des messages via WebSocket
  receiveMessages(): Observable<any> {
    return new Observable((observer) => {
      const destination = '/all/messages';
      this.rxStompService.watch(destination).subscribe({
        next: (message) => {
          console.log('Message reçu via WebSocket :', message.body);
          observer.next(JSON.parse(message.body));
        },
        error: (error) => {
          console.error('Erreur lors de l’écoute des messages WebSocket :', error);
        },
      });
    });
  }

  // Recevoir des messages privés
  receivePrivateMessages(): Observable<ChatMessage> {
    return new Observable((observer) => {
      const destination = '/user/chat/private-messages';
      this.rxStompService.watch(destination).subscribe({
        next: (message) => {
          console.log('Message privé reçu via WebSocket :', message.body);
          observer.next(JSON.parse(message.body));
        },
        error: (error) => {
          console.error('Erreur lors de l’écoute des messages privés :', error);
        },
      });
    });
  }
}
